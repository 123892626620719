.sl{
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sl-texts{
    width: 65%;
}

.sl-title{
    font-size: 50px;
    font-weight:600;
}

.sl-desc{
    margin: 20px 0px;
}

.sl-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:480px) {

    .sl{
      padding: 10px;
      
    }

    .sl-list {
        display: flex;
        justify-content: center;
        width: 110%;
        
      }
      
    .sl-texts{
      width:100%;
      
    }
  }