.project {
  width: 100%;
  height: calc(140vh);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}



.project h1 {
  margin-top: 50px;
  color: black;
  font-size: 40px;
}

.project img {
  width: 700px;
  border-radius: 10px;
  opacity: 1;
}

.project p {
  font-size: 30px;
  color:black;
  margin: 40px 30px 0 30px;

}



.video-stickordie-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(105, 105, 105, 0.596);
}

.video-stickordie {
  box-shadow: 0 0 5px rgba(105, 105, 105, 0.596);
  
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  margin: 20px;
  margin: 20px;
  
  box-shadow: 0 0 5px rgba(105, 105, 105, 0.596);
  
}

@media screen and (max-width:480px) {
  .project img {
    width: 70%;
    display: block;
    margin: 0 auto;
  }
  

  .project h1 {
    margin-top: 50px;
    color: black;
    font-size: 18px;
  }

  html, body {
    margin: 0;
    padding: 0;
  }
  
  .video-stickordie {
    box-shadow: 0 0 5px rgba(105, 105, 105, 0.596);
    max-width: 90%;
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    max-width: 100%;
  }

  .video {
    max-width: 90%;
    
  }

  .project {
    width: 100%;
    height: 100%; /* Subtract any fixed header/footer height */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    
  }

  .project p {
    font-size: 17px;
  }
}
